export enum AdminRole {
    SUPER_ADMIN = "super_admin",
    ADMIN = "admin",
    IN_TRANSIT_EXECUTIVE = "intransit_executive",
    TSO_LORRY = "tso_lorry",
    FULLFILMENT_EXECUTIVE = "fullfilment_executive"
}

// [
//     "admin",
//     "admin_with_block_access",
//     "asm",
//     "data_admin",
//     "executive",
//     "fos",
//     "fullfilment_admin",
//     "fullfilment_executive",
//     "intransit_admin",
//     "intransit_executive",
//     "kam",
//     "kyc_executive",
//     "lv_admin",
//     "lv_executive",
//     "membership_sales_executive",
//     "metabase_executive",
//     "tsm",
//     "tso_load",
//     "tso_lorry",
//     "vas_admin",
//     "vas_executive",
//     "zsm"
// ];
